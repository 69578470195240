<template>
    <canvas :id="id" class="chart-canvas" width="100%" height="100%"></canvas>
</template>

<script>
import { Chart } from "chart.js";
import Colours from "@/utils/Colours";
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";
import UpdateSetting from "@/utils/UpdateSetting";

export default {
    data() {
        return {
            existingCharts: null,
            chartData: null,
            speedUnit: getMeasurementUnit(MeasurementTypes.Speed)
        };
    },
    props: {
        id: String,
        player: Object,
        startTime: String,
        endTime: String,
        sessionStartTime: String,
        sessionEndTime: String,
        stepSize: Number,
        useDrillTimeRange: Boolean
    },
    watch: {
        player: {
            async handler(newPlayer) {
                if (newPlayer && newPlayer.data) {
                    this.chartData = await this.getChartData(newPlayer, this.startTime, this.endTime);
                    this.renderChart(this.id, this.chartData);
                }
            },
            deep: true,
        },
        async startTime(newStartTime) {
            this.chartData = await this.getChartData(this.player, newStartTime, this.endTime);
            this.renderChart(this.id, this.chartData);
        },
        async endTime(newEndTime) {
            this.chartData = await this.getChartData(this.player, this.startTime, newEndTime);
            this.renderChart(this.id, this.chartData);
        },
        async useDrillTimeRange() {
            this.renderChart(this.id, this.chartData);
        }
    },
    methods: {
        async getChartData(player, startTime, endTime) {
            if (!player || !player.data) return null;

            const startSeconds = this.timeStringToSeconds(startTime);
            const endSeconds = this.timeStringToSeconds(endTime);

            const allTimes = Object.keys(player.data);
            const allSpeeds = allTimes.map(t => player.data[t]);


            const filteredTimes = allTimes.map((t, index) => {
                const timeInSeconds = this.timeStringToSeconds(t);
                return timeInSeconds >= startSeconds && timeInSeconds <= endSeconds
                    ? allSpeeds[index]
                    : null;
            });

            return {
                labels: allTimes,
                datasets: [
                    {
                        data: allSpeeds,
                        borderColor: Colours.TRANSLUCENT_SPORTLIGHT_TEAL,
                        borderWidth: 1,
                        fill: false,
                        pointRadius: 0,
                        pointHoverRadius: 5,
                    },
                    {
                        label: "Highlighted Area",
                        data: filteredTimes,
                        borderColor: Colours.SPORTLIGHT_TEAL,
                        backgroundColor: Colours.TRANSLUCENT_SPORTLIGHT_TEAL,
                        borderWidth: 0,
                        fill: true,
                        pointRadius: 0,
                    },
                ],
            };
        },

        timeStringToSeconds(timeString) {
            const [hours, minutes, seconds] = timeString.split(":").map(Number);
            return hours * 3600 + minutes * 60 + (seconds || 0);
        },

        renderChart(canvasId, chartData) {
            if (!chartData) return;

            if (this.existingCharts !== null) {
                this.existingCharts.destroy();
            }

            const chartCtx = document.getElementById(canvasId);
            this.existingCharts = new Chart(chartCtx, {
                type: "line",
                data: chartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 0,
                    },
                    legend: { display: false },
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem)=> {
                                if (tooltipItem.datasetIndex === 1) {
                                    return null; // Hide tooltip for highlighted area
                                }
                                return `Speed: ${this.speedUnit.formatStandard(tooltipItem.yLabel) }${this.speedUnit.unitShort}`;
                            },
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                type: "time",
                                time: {
                                    parser: "HH:mm:ss",
                                    unit: "second",
                                    stepSize: this.stepSize,
                                    displayFormats: {
                                        second: "HH:mm:ss",
                                    },
                                },
                                ticks: {
                                    display: true,
                                    min: this.useDrillTimeRange ? this.startTime : this.sessionStartTime,
                                    max: this.useDrillTimeRange ? this.endTime : this.sessionEndTime,
                                },
                                gridLines: {
                                    display: true,
                                    color: Colours.PRIMARY_GREY,
                                    lineWidth: 0,
                                    zeroLineColor: Colours.WHITE,
                                    zeroLineWidth: 1,
                                    drawBorder: true,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                ticks: { beginAtZero: true, padding: 0 },
                                scaleLabel: { display: false },
                                gridLines: {
                                    display: true,
                                    color: Colours.PRIMARY_GREY,
                                    lineWidth: 0,
                                    zeroLineColor: Colours.WHITE,
                                    zeroLineWidth: 1,
                                    drawBorder: true,
                                },
                            },
                        ],
                    },
                },
            });
            this.existingCharts.update();
        },
    },
    async mounted() {
        if (this.player && this.player.data) {
            this.chartData = await this.getChartData(this.player, this.startTime, this.endTime);
            this.renderChart(this.id, this.chartData);
            UpdateSetting();
        }
    },
};
</script>